import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit, Injector } from '@angular/core';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { QueryService } from 'src/core/services/query/query.service';

@Component({
  selector: 'ca-query-index-nearnot-completed-notification',
  templateUrl: './query-index-nearnot-completed-notification.component.html',
  styleUrls: ['./query-index-nearnot-completed-notification.component.scss'],
})
@NotificationType(
  'QueryIndexNearOrNotCompletedNotification',
  QueryIndexNearOrNotCompletedNotificationComponent
)
export class QueryIndexNearOrNotCompletedNotificationComponent implements OnInit {
  data: NotificationDto;

  get phrases(): string {
    return this.data ? JSON.parse(this.data.payload).phrases : '';
  }

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private service: QueryService,
    private localizationService: LocalizationService,
    private toastr: ToasterService
  ) {}

  ngOnInit(): void {}

  navigate() {
    const parsedPayload = JSON.parse(this.data.payload);

    this.service.existTempQuery(parsedPayload.queryId).subscribe(response => {
      if (response == true) {
        this.router.navigate(['/conversation'], {
          queryParams: {
            queryId: parsedPayload.queryId,
            isTempQuery: true,
          },
        });
      } else {
        this.toastr.warn(
          this.localizationService.instant('Query::ExistTempQueryWarningMessage', this.phrases)
        );
      }
    });

    this.notificationService.getReadStatus(this.data.id).subscribe(result => {
      if (!result) {
        this.notificationService.markAsRead(this.data.id).subscribe();
      }
    });
  }

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'Query::IndexingNearNotCompletedNotification',
      parsedPayload.phrases
    );

    const toastrId = toastr.success(message, null, {
      sticky: true,
    });

    const toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(
        injector,
        notificationService,
        notificationId,
        parsedPayload.queryId,
        localizationService,
        router
      );
    });
  }

  static toasterClickedHandler(
    injector: Injector,
    notificationService: NotificationService,
    notificationId: string,
    queryId: string,
    localizationService: LocalizationService,
    router: Router
  ) {
    router.navigate(['/conversation'], {
      queryParams: {
        queryId: queryId,
        isTempQuery: true,
      },
    });
    notificationService.markAsRead(notificationId).subscribe();
  }
}
