import { Router, ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { Observable, takeUntil } from 'rxjs';
import { ConversationListResponseDto } from 'src/core/models/conversation/conversation-list-response.dto';
import { Select, Store } from '@ngxs/store';
import { ConversationListType } from 'src/core/models/generic-lookup-type/conversation/conversation-list-type.glt';
import { ConversationLayoutService } from 'src/core/services/conversation/conversation-layout.service';
import { LayoutConfig } from 'src/core/models/split/layout-config.model';
import { MatTableDataSource } from '@angular/material/table';
import { ToasterService } from '@abp/ng.theme.shared';
import { PageEvent } from '@angular/material/paginator';
import {
  DataRequest,
  ListeningStatusChange,
  SorterChange,
} from 'src/core/actions/conversation/conversation-module.actions';
import { Sort } from '@angular/material/sort';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { CompactPlayerComponent } from 'src/ca-shared/player/compact-player/compact-player.component';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { ColumnConfiguratorService } from 'src/ui/column-configurator/column-configurator.module';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';
import { ConversationListeningStatus } from 'src/core/models/generic-lookup-type/conversation/conversation-listening-status.glt';
import { ConversationReferrer } from 'src/core/constants/conversation-referrer.constant';

@Component({
  selector: 'ca-conversation-base-list',
  templateUrl: './conversation-base-list.component.html',
  styleUrls: ['./conversation-base-list.component.scss'],
})
@AutoUnsubscribe()
export class ConversationBaseListComponent
  extends HasSubscription
  implements OnInit, AfterViewInit, OnDestroy
{
  @Select(ConversationModuleState.getData)
  data$: Observable<ConversationListResponseDto>;

  @Select(ConversationModuleState.getActiveList)
  activeList$: Observable<number>;

  @Select(ConversationModuleState.dataRequested)
  dataRequested$: Observable<number>;

  @Select(ConversationModuleState.getCurrentLayout)
  layout$: Observable<LayoutConfig>;

  @Select(ConversationModuleState.getCurrentPage)
  currentPage$: Observable<number>;

  @Select(ConversationModuleState.getSortField)
  sortField$: Observable<string>;

  @Select(ConversationModuleState.getSortDirection)
  sortDirection$: Observable<'asc' | 'desc' | ''>;

  @Input()
  dataSource = new MatTableDataSource<ConversationDto>();

  @Input()
  set gridColumns(value: MatTableColumnDefinitionModel[]) {
    if (this._gridColumns.length <= 0) {
      this._gridColumns = value;
    }
  }

  get gridColumns(): MatTableColumnDefinitionModel[] {
    return this._gridColumns;
  }

  @Input()
  gridId: string;

  @Input()
  compactPlayer: CompactPlayerComponent;

  @BlockUI('conversation-table-block') tableBlock: NgBlockUI;

  @Output()
  conversationIdChange: EventEmitter<{
    id: number;
  }> = new EventEmitter();

  lastConversationId: number;

  data: ConversationDto[] = [];
  displayedColumns: string[] = [];
  listType = ConversationListType;
  totalCount = 0;
  isDetailPanelOpen: boolean = false;
  currentPage = 0;
  isLoading = false;
  conversationType = ConversationType;
  userPhotoComponentStyles: any = {
    width: '25px',
    height: '25px',
    'border-radius': '50%',
  };
  sentimentFeatureEnabled = false;

  _gridColumns: MatTableColumnDefinitionModel[] = [];

  get playingConversationId(): number {
    if (this.compactPlayer && this.compactPlayer.conversation) {
      return this.compactPlayer.conversation.id;
    }
    return -1;
  }

  get audioPlaying(): boolean {
    if (this.compactPlayer && this.compactPlayer.playing) {
      return true;
    }
    return false;
  }

  get audioLoading(): boolean {
    if (this.compactPlayer && this.compactPlayer.loading) {
      return true;
    }
    return false;
  }

  constructor(
    private conversationLayoutService: ConversationLayoutService,
    private conversationService: ConversationService,
    private toastr: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private featureService: FeatureService,
    private columnConfiguratorService: ColumnConfiguratorService
  ) {
    super();

    this.sentimentFeatureEnabled = this.featureService.isEnabled(
      FeatureConstants.SentimentAnalysis
    );
  }

  ngOnInit() {
    this.columnConfiguratorService.connect(this.gridId, this.gridColumns, this.displayedColumns);

    this.data$.pipe(takeUntil(this.autoUnsubscribeNotifier)).subscribe(data => {
      this.data = data === null ? [] : data.items;
      this.totalCount = data.totalCount;
      this.dataSource.connect().next(this.data);
    });

    this.dataRequested$.pipe(takeUntil(this.autoUnsubscribeNotifier)).subscribe(requested => {
      if (requested != null) {
        this.tableBlock.reset();
        this.tableBlock.start();
        this.isLoading = true;
      } else {
        this.tableBlock.stop();
        this.isLoading = false;
      }
    });

    this.layout$.pipe(takeUntil(this.autoUnsubscribeNotifier)).subscribe(cfg => {
      if (!cfg.east.visible) {
        this.clearSelection();
      }
      this.isDetailPanelOpen = cfg.east.visible;
    });

    this.currentPage$.pipe(takeUntil(this.autoUnsubscribeNotifier)).subscribe(currentPage => {
      this.currentPage = currentPage;
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  openDetailPanel(eventArgs, conversation: ConversationDto, conversationId: number) {
    const _conversationId = (conversation && conversation!.id) || conversationId;
    this.conversationLayoutService.changeVisibilityForDetailPanel(true);
    this.selectRow(_conversationId);
    this.conversationIdChange.emit({ id: _conversationId });
    this.lastConversationId = _conversationId;
    this.isDetailPanelOpen = true;
  }

  closeDetailPanel(conversation: ConversationDto) {
    this.conversationLayoutService.changeVisibilityForDetailPanel(false);
    this.isDetailPanelOpen = false;
  }

  openOrCloseDetailPanel(conversation: ConversationDto) {
    if (this.isDetailPanelOpen && conversation.id == this.lastConversationId) {
      this.closeDetailPanel(conversation);
    } else {
      this.openDetailPanel(null, conversation, null);
    }
  }

  onRelatedGroupIdClick(eventArgs) {
    this.conversationService.filterRelatedGroupId.next(eventArgs);
  }

  onRowDoubleClick(eventArgs, conversationId: number) {
    this.openDetailPanel(null, null, conversationId);
  }

  onPlayButtonClick(conversation: ConversationDto) {
    if (this.compactPlayer) {
      if (
        !this.compactPlayer.conversation ||
        this.compactPlayer.conversation.id != conversation.id
      ) {
        this.compactPlayer.setAndPlayAudio(conversation);
      } else {
        this.compactPlayer.onPlayAudio();
      }
      this.setListeningStatus(conversation);
    }
  }

  onPauseButtonClick(conversation: ConversationDto) {
    if (this.compactPlayer) {
      this.compactPlayer.onPauseAudio();
    }
  }

  setListeningStatus(conversation: ConversationDto) {
    const action = new ListeningStatusChange(
      ConversationListeningStatus.listenedBeforeByYou,
      conversation.id
    );
    this.store.dispatch(action);
  }

  selectRow(conversationId: number): void {
    this.clearSelection();

    const targetClass = 'conversation-row-' + conversationId;
    const selector = 'tr.' + targetClass;
    const rowEl: HTMLTableRowElement = document.querySelector(selector);

    rowEl.classList.add('selected');
  }

  clearSelection(): void {
    const selector = 'tr.selected';
    const rowEls: NodeListOf<HTMLTableRowElement> = document.querySelectorAll(selector);

    rowEls.forEach((rowEl: HTMLTableRowElement) => {
      rowEl.classList.remove('selected');
    });
  }

  indexConversation(id: number): void {
    this.conversationService.indexConversation(id).subscribe(data => {
      this.toastr.success('Conversation::ConversationAddedToIndexingQueue', '', {});
    });
  }

  openFullscreen(conversationId: number) {
    let navigationExtras = {};
    let queryParams = this.getQueryParams();
    let state = this.getState();
    if (queryParams || state) {
      navigationExtras['queryParams'] = queryParams;
      navigationExtras['state'] = state;
      this.router.navigate(['/conversation', 'v2', conversationId], navigationExtras);
    } else {
      this.router.navigate(['/conversation', 'v2', conversationId]);
    }
  }

  getQueryParams() {
    let queryParams = {};

    let quickSearchTermFilter = this.conversationService.filters.filter(
      x => x.field === 'quickSearchTerm'
    );
    if (quickSearchTermFilter != null) {
      queryParams['quickSearchTerm'] = quickSearchTermFilter.map(x => x.value);
    }

    const userDefinedCategoryFilter = this.conversationService.filters.find(
      x => x.field === 'query'
    );
    if (userDefinedCategoryFilter != null && userDefinedCategoryFilter.value.length > 0) {
      queryParams['userDefinedCategoryId'] = userDefinedCategoryFilter.value[0];
    }

    const aiGeneratedCategoryFilter = this.conversationService.filters.find(
      x => x.field === 'topic'
    );
    if (aiGeneratedCategoryFilter != null && aiGeneratedCategoryFilter.value.length > 0) {
      queryParams['aiGeneratedCategoryId'] = aiGeneratedCategoryFilter.value[0];
    }

    var resultId = this.route.snapshot.params.resultId;

    if (resultId != null) {
      queryParams['resultId'] = resultId;
    }

    if(this.conversationService.tempQueryId > 0){
      queryParams['isTempQuery'] = true;
      queryParams['queryId'] = this.conversationService.tempQueryId;
      queryParams['referrer'] = ConversationReferrer.tempQuery;
    }

    return queryParams;
  }

  getState() {
    let state = { enableQuickNavigation: this.totalCount > 1 };
    const topicFilter = this.conversationService.filters.find(x => x.field === 'topic');
    if (topicFilter != null && topicFilter.value.length > 0) {
      state['topicIds'] = topicFilter.value;
    }

    return state;
  }

  sortData(eventArgs: Sort) {
    const action = new SorterChange(eventArgs);
    this.store.dispatch(action);
  }

  pageChanged(event?: PageEvent) {
    const dataRequestAction = new DataRequest(event.pageIndex);
    this.store.dispatch(dataRequestAction);
  }

  copyToClipboard(): void {
    this.toastr.success('::CopiedToClipboard', '', {});
  }

  reSizeColumn(width) {
    let widthReplace = width.replace('px', '');

    const result = widthReplace - 50 + 'px';

    return result;
  }
}
